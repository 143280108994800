<template>
  <div class="header__icons">
    <v-menu offset-y nudge-top="-20" v-if="currentRole == 7">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          height="40px"
          width="40px"
          style="min-width: unset; left: 10px; border-radius: var(--8, 8px)"
          class="ml-5 mr-3 p-0 genaralIcons"
          color="#3f0e40"
        >
          <v-icon color="white" size="40">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
          @click="backToReceivedBasket"
        >
          <v-list-item-icon class="ml-3">
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>إرجاع للمستلمة</v-list-item-title>
        </v-list-item>

        <v-list-item
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
          @click="requestArchived"
          v-if="!showFeaturedProcess"
        >
          <v-list-item-icon class="ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.171"
              height="20.078"
              viewBox="0 0 18.171 20.078"
            >
              <g
                id="Icon_feather-trash-2"
                data-name="Icon feather-trash-2"
                transform="translate(-4 -2.5)"
              >
                <path
                  id="Path_5856"
                  data-name="Path 5856"
                  d="M4.5,9H21.671"
                  transform="translate(0 -2.184)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_5857"
                  data-name="Path 5857"
                  d="M20.855,6.816V20.171a1.908,1.908,0,0,1-1.908,1.908H9.408A1.908,1.908,0,0,1,7.5,20.171V6.816m2.862,0V4.908A1.908,1.908,0,0,1,12.27,3h3.816a1.908,1.908,0,0,1,1.908,1.908V6.816"
                  transform="translate(-1.092)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_5858"
                  data-name="Path 5858"
                  d="M15,16.5v5.724"
                  transform="translate(-3.823 -4.915)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_5859"
                  data-name="Path 5859"
                  d="M21,16.5v5.724"
                  transform="translate(-6.007 -4.915)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>ارشفه الطلب</v-list-item-title>
        </v-list-item>
        <v-list-item
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
          @click="handleTransferFilter"
        >
          <v-list-item-icon class="ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.513"
              height="16.448"
              viewBox="0 0 14.513 16.448"
            >
              <path
                id="Icon_material-subdirectory-arrow-right"
                data-name="Icon material-subdirectory-arrow-right"
                d="M20.513,16.643l-5.805,5.805-1.374-1.374,3.473-3.464H6V6H7.935v9.675h8.872l-3.473-3.464,1.374-1.374Z"
                transform="translate(-6 -6)"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title>تحويل الطلب</v-list-item-title>
        </v-list-item>
        <v-list-item
          style="border-bottom: 2px solid #ededed"
          class="black--text font-weight-bold"
          @click="moveToNeedAction"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.121"
              height="17.121"
              viewBox="0 0 17.121 17.121"
            >
              <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
              >
                <path
                  id="Path_5860"
                  data-name="Path 5860"
                  d="M10.5,25.5l15-15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_5861"
                  data-name="Path 5861"
                  d="M10.5,10.5h15v15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>نقل الي سله التحويل</v-list-item-title>
        </v-list-item>
        <v-list-item
          style="border-bottom: 2px solid #ededed"
          class="black--text font-weight-bold"
          @click="convertRequest"
          v-if="!showFeaturedProcess"
        >
          <v-list-item-icon class="ml-2">
            <SendToQualityIcon />
          </v-list-item-icon>
          <v-list-item-title>نقل الي الجوده</v-list-item-title>
        </v-list-item>
        <v-list-item
          style="border-bottom: 2px solid #ededed"
          class="black--text font-weight-bold"
          @click="moveToFreez"
          v-if="!showFeaturedProcess"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.645"
              height="17.645"
              viewBox="0 0 17.645 17.645"
            >
              <g
                id="Icon_feather-minus-square"
                data-name="Icon feather-minus-square"
                transform="translate(-4 -4)"
              >
                <path
                  id="Path_5862"
                  data-name="Path 5862"
                  d="M6.349,4.5H19.3a1.849,1.849,0,0,1,1.849,1.849V19.3A1.849,1.849,0,0,1,19.3,21.145H6.349A1.849,1.849,0,0,1,4.5,19.3V6.349A1.849,1.849,0,0,1,6.349,4.5Z"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_5863"
                  data-name="Path 5863"
                  d="M12,18h7.4"
                  transform="translate(-2.876 -5.178)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>تجميد</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="45px"
          v-bind="attrs"
          v-on="on"
          style="z-index: 5; left: 80px; top: -53px; position: absolute"
        >
          <svg
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="#3f0e40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40"
            />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item
          @click="$store.state.views = 'default'"
          v-if="appearThirdView"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="#3F0E40"
            >
              <rect x="2" y="5" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="10" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="16" width="12" height="3" fill="#3F0E40" />
              <rect x="2" y="21" width="12" height="3" fill="#3F0E40" />
              <rect
                x="16.4424"
                y="5"
                width="11.5385"
                height="8.65385"
                rx="1"
                fill="#3F0E40"
              />
              <rect
                x="16.4424"
                y="15.0957"
                width="11.5385"
                height="8.65385"
                rx="1"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>

          <v-list-item-title style="line-height: 2">
            عرض الملفات
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.views = 'cards'"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#3F0E40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">
            عرض الكروت</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.views = 'table'"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
              fill="#3F0E40"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2"
            >عرض الجدول</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      @click="$store.state.search_admin = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 125px; border-radius: 8px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="auto">
          <v-card>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-text>
              {{ messages }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="dialog = false"
              >
                الغاء
              </v-btn>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="sureToArchive"
              >
                متاكد
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <TransferFilterDialog :selectedItems="selectedItems" :type="type" />

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogQuality" persistent width="624">
          <v-card>
            <v-card-title>
              <span class="text-h5" style="margin-bottom: 30px"
                >نقل الطلب الي الجوده</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <SelectInput
                      @selectedValues="getSelectedValueManger"
                      :originalItems="qualityUsers"
                      label="الجوده"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="dialogQuality = false"
              >
                الغاء
              </v-btn>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="handleSendUserToAgent"
              >
                تحويل
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-snackbar
      v-model="active"
      :color="snackColor"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{
        snackMessage
      }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <template>
      <v-row justify="center">
        <v-dialog v-model="backToReceivedDialog" persistent width="650">
          <v-card>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-text>
              <h2>هل انت متأكد من ارجاع الطلبات لسلة المستلمه ؟</h2>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="backToReceivedDialog = false"
              >
                الغاء
              </v-btn>
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="sureToBackToReceivedBasket"
              >
                متاكد
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

.header__icons .v-input__slot {
  border: 1px solid white;
}
@media screen and (max-width: 626px) {
  .genaralIcons {
    top: -10px;
  }
}

.white-border {
  .v-input--outlined {
    border-color: white !important; /* Set the border color to white */
  }

  .v-label {
    color: white !important; /* Set the label color to white */
  }

  .v-icon {
    color: white !important; /* Set the icon color to white */
  }

  input {
    color: white !important; /* Set the input text color to white */
  }
}
</style>

<script>
import Axios from "axios";
import TransferFilterDialog from "@/components/AdminComponent/TransferFilterDialog.vue";
import SelectInput from "@/components/Charts/SelectInput.vue";
import { qualityUser } from "@/mixins/qualityUser";
import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";

export default {
  name: "HeaderIcons",
  components: {
    SelectInput,
    TransferFilterDialog,
    SendToQualityIcon,
  },
  props: ["selectedItems", "startItems", "type"],
  data() {
    return {
      currentRole:0,
      qualityUsers: [],
      active: false,
      AllSelectedTable: [],
      dialog: false,
      backToReceivedDialog: false,
      message: "",
      snackColor: "",
      messages: "هل انت متاكد من رغبتك في ارشفت هذه الطلبات",
      allStatus: [],
      dialogQuality: false,
      selectedQuality: [],
      needToAction: [],
      snackMessage: "",
    };
  },
  computed: {
    appearThirdView() {
      return (
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === "/admin/requests/all"
      );
    },
    showFeaturedProcess() {
      return window.location.pathname === "/admin/requests/featured";
    },

    showNewView() {
      return window.location.pathname === "/admin/requests/all";
    },
  },
  methods: {
    handleTableDesign() {
      this.$store.state.views = "table";
    },

    handleSendUserToAgent() {
      this.dialogQuality = false;

      let url =
        "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveToQualityReqArray";
      let body = new FormData();

      this.selectedQuality.forEach((item, index) => {
        body.append(`quality[${index}]`, item["id"]);
      });

      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.snackMessage = res.data.message;
          this.snackColor = "green";
          this.active = true;
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.snackColor = "red";
          this.active = true;
        });
    },

    getSelectedValueManger(val) {
      this.selectedQuality = val;
    },
    async convertRequest() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.dialogQuality = true;

        await qualityUser().then((res) => {
          this.qualityUsers = res.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["name_for_admin"] || item["name"];
            return item;
          });
        });
      }
    },

    moveToNeedAction() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.messages = "هل انت متأكد من تحويل هذه الطلبات";
        this.dialog = true;
      }
    },

    moveToFreez() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.messages = "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد";
        this.dialog = true;
      }
    },

    handleTurnuser() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.$store.state.turnuserDialog = true;
      }
    },

    handleTransferFilter() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.$store.state.TransferFilterDialog = true;
      }
    },

    requestArchived() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.dialog = true;
      }
    },

    backToReceivedBasket() {
      if (
        this.selectedItems.length ||
        this.$store.state.startElement.length > 0
      ) {
        this.backToReceivedDialog = true;
      }
    },

    sureToArchive() {
      if (this.messages === "هل انت متأكد من تحويل هذه الطلبات") {
        this.dialog = false;
        let url =
          "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveToNeedActionReqArray";
        let body = new FormData();
        this.selectedItems.length > 0 &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length > 0 &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (
        this.messages === "هل انت متاكد من رغبتك في ارشفت هذه الطلبات"
      ) {
        let url =
          "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/Archive ";
        let body = new FormData();

        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.dialog = false;

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.payload;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (
        this.messages === "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد"
      ) {
        this.dialog = false;
        let url =
          "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/transferToFreezeReqArray";
        let body = new FormData();
        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        Axios.post(url, body, { headers: this.request_headers() })
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
            this.$emit("getData");
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      }
    },

    sureToBackToReceivedBasket() {
      let url =
        "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveFromAllToRecieved";
      let body = new FormData();
      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.backToReceivedDialog = false;

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.snackMessage = res.data.message;
          this.snackColor = "green";
          this.active = true;
          this.$emit("getData");
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.snackColor = "red";
          this.active = true;
        });
    },
  },
  mounted(){
    this.currentRole = localStorage["role"];
  },
};
</script>
